<template>
  <b-card no-body>
    <b-card-body>
      <div class="d-flex flex-column mb-1">
        <small class="text-muted">Баланс в ₽</small>
        <span class="fs-24 font-weight-bolder">
          {{User.self.balance.formatMoney('','','')}} ₽
        </span>
      </div>
      <b-button block variant="primary">Вывести</b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import User from "@/modules/user/"

export default {
  data() {
    return {
      User
    }
  }
};
</script>

<style></style>
