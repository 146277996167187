<template>
  <b-card no-body>
    <b-card-body>
      <div class="d-flex flex-column mb-1">
        <small class="text-muted">Баланс 2workcoin</small>
        <div class="d-flex align-items-center">
        <span class="fs-24 font-weight-bolder">
          50 </span> <div
              class="currency-icon"
              style="
                background-size: 18px 18px;
                width: 18px;
                height: 18px;
                margin-left: 4px;
              "
            ></div>
        </div>
      </div>
      <b-button block variant="outline-primary">Пополнить</b-button>
    </b-card-body>
  </b-card>
</template>

<script>
export default {};
</script>

<style></style>
