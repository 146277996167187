var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-card-content"},[_c('b-card-body',{staticStyle:{"padding":"0"}},[_c('b-card-title',[_vm._v("Добавьте данные карты")]),_c('validation-observer',{ref:"new_card"},[_c('b-form',{staticClass:"client-form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Номер карты","label-for":"number"}},[_c('validation-provider',{attrs:{"name":"Номер карты","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#### #### #### #######'),expression:"'#### #### #### #######'"}],attrs:{"placeholder":"Номер карты"},model:{value:(_vm.form.number),callback:function ($$v) {_vm.$set(_vm.form, "number", $$v)},expression:"form.number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Имя","label-for":"holder_name"}},[_c('validation-provider',{attrs:{"name":"Имя","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Имя владельца"},model:{value:(_vm.form.holder_name),callback:function ($$v) {_vm.$set(_vm.form, "holder_name", $$v)},expression:"form.holder_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Срок годности","label-for":"expiration"}},[_c('validation-provider',{attrs:{"name":"Срок годности","rules":{ required: true, regex: /(0[1-9]|1[0-2])\/[2-9]{2}/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##'),expression:"'##/##'"}],attrs:{"name":"Expiration","placeholder":"Срок годности"},model:{value:(_vm.form.expiration),callback:function ($$v) {_vm.$set(_vm.form, "expiration", $$v)},expression:"form.expiration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"CVV/CVC","label-for":"cvv"}},[_c('validation-provider',{attrs:{"name":"CVV/CVC","rules":"required|length:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],attrs:{"name":"CVV/CVC","placeholder":"CVV/CVC","type":"password"},model:{value:(_vm.form.cvv),callback:function ($$v) {_vm.$set(_vm.form, "cvv", $$v)},expression:"form.cvv"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"type":"submit","block":"","variant":"primary","disabled":_vm.is_loading},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_vm._v(" Сохранить реквизиты ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }