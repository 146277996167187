<template>
  <div>
    <b-card
      no-body
      v-ripple
      style="cursor: pointer"
      v-for="(card, i) in cards"
      :key="i"
    >
      <b-card-body 
        class="d-flex flex-row align-items-center"
      >
        <img
          alt="alfa"
          class="mr-1"
          :src="
            require('@/assets/images/pages/finance/cards/' +
              card.identificator.toLowerCase() +
              '.svg')
          "
        />
        <div class="d-flex flex-column">
          <b-card-text class="fs-16" style="margin: 0">
            {{ card.name }}
          </b-card-text>
          <div class="d-flex align-items-center">
            <b-card-text class="text-muted" style="margin: 0 8px 0 0">
              {{ card.type }}
            </b-card-text>
            <!-- style="width: 60px; height: 60px; margin-right: 16px" -->
            <img
              alt="type_card"
              :src="
                require('@/assets/images/pages/finance/cards/types/' +
                  card.type.toLowerCase() +
                  '.svg')
              "
            />
            <div class="ellipse"></div>
            <b-card-text class="text-muted">
              {{ card.number }}
            </b-card-text>
          </div>
        </div>
      </b-card-body>
    </b-card>

    <b-card
      no-body
      v-ripple
      @click="modalsVisibility.new = true"
      class="d-flex flex-row align-items-center"
      style="cursor: pointer; padding: 20px"
    >
      <img
        alt="new"
        class="mr-1"
        src="@/assets/images/pages/finance/cards/add_new.svg"
      />
      <b-card-text class="fs-16 user-select-none" style="margin: 0">
        Добавить новую карту
      </b-card-text>
    </b-card>

    <b-modal
      id="new-card-modal"
      v-model="modalsVisibility.new"
      centered
      hide-footer
    >
      <new-card-form />
    </b-modal>
  </div>
</template>

<script>
import NewCardForm from "../modals/addcard";

export default {
  data() {
    return {
      modalsVisibility: {
        new: false,
      },

      cards: [
        {
          id: 1,
          name: "Альфабанк",
          identificator: "alfabank",
          type: "MasterCard",
          number: "6439",
        },
        {
          id: 2,
          name: "Тинькофф банк",
          identificator: "tinkoff",
          type: "VISA",
          number: "6119",
        },
      ],
    };
  },
  methods: {
    add() {},
  },
  components: {
    NewCardForm,
  },
};
</script>

<style lang="scss">
.ellipse {
  width: 4px;
  height: 4px;
  margin: 0 8px;

  background: #a3aec4;

  border-radius: 50px;
}
</style>
