<template>
  <div class="date-filters-finance">
    <div class="date-filters mb-2">
      <div
        class="date-filters__item"
        v-for="(val, index) in filters_date"
        :key="index"
        :class="{ active: val.indicator == selected }"
        @click="select(val)"
      >
        {{ val.title }}
      </div>
    </div>

    <b-modal
      id="fiter-date-modal"
      v-model="modalsVisibility.date"
      centered
      title="Выбор даты"
      hide-footer
    >
      <date-modal-content
        @changePeriod="changePeriod"
        :period="period"
      />
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';

import dateModalContent from '../modals/date';

export default {
  data() {
    return {
      modalsVisibility: {
        date: false,
      },
      selected: 'today',
      period: {
        start: '',
        end: ''
      },
      filters_date: [
        {
          indicator: 'today',
          title: 'Сегодня',
        },
        {
          indicator: 'yesterday',
          title: 'Вчера',
        },
        {
          indicator: 'week',
          title: 'За неделю',
        },
        {
          indicator: 'month',
          title: 'За месяц',
        },
        {
          indicator: 'all',
          title: 'За все время',
        },
        {
          indicator: 'period',
          title: 'Свой период',
        },
      ],
    };
  },
  methods: {
    changePeriod(period) {
      this.period = period
      this.modalsVisibility.date = false;
      this.$emit('changeFilter', {
        select: this.selected,
        period: this.period
      })
    },
    select(val) {
      // this.$router.replace({ query: {} })
      this.selected = val.indicator;
      if (val.indicator == 'period') {
        this.modalsVisibility.date = true;
      } else {
        this.$emit('changeFilter', {
          select: this.selected,
          period: this.period
        })
      }
    },
  },
  watch: {
  },
  mounted() {
   
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    dateModalContent,
  },
};
</script>

<style lang="scss" scoped>
.date-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  &__item {
    cursor: pointer;
    background: transparent;
    border: 1px solid var(--link-primary);
    border-radius: 60px;
    padding: 6px 14px;
    color: var(--link-primary);
  }
  &__item.active {
    color: #fff;
    background-color: var(--link-primary);
  }
}
.date-filters-finance {
  @media screen and (max-width: 1128px) {
    .vs__search,
    .vs__search:focus {
      font-size: 16px;
    }

    .col-md-6 {
      padding-bottom: 8px;
    }

    .form-group {
      padding: 4px 20px !important;
      margin: 0 !important;
    }
  }
}
</style>
