<template>
  <b-card no-body style="padding: 24px">
    <div class="d-flex justify-content-between mb-2 align-items-start">
      <b-card-title> История операций </b-card-title>
      <div class="download-btn" @click="exportExcel">
        <feather-icon icon="DownloadIcon" style="margin-right: 8px" size="18" />
        <span>Сохранить отчет</span>
      </div>
    </div>

    <filters-view @changeFilter="changeFilter" />

    <b-card-body
      class="d-flex flex-column pb-5"
      v-if="!is_loading && transactions.items.length === 0"
    >
      <div class="d-flex flex-column justify-content-center">
        <div class="nodata-icon ml-auto mr-auto"></div>
        <p class="text-muted mt-1 ml-auto mr-auto text-center">
          Нет данных для отображения
        </p>
      </div>
    </b-card-body>

    <b-skeleton-table
      v-else-if="is_loading"
      animation="fade"
      :columns="5"
      :rows="5"
    >
    </b-skeleton-table>

    <b-table-simple v-else-if="transactions" id="table-transactions" responsive>
      <b-thead>
        <b-tr>
          <b-th>Дата и время</b-th>
          <b-th>Операция</b-th>
          <b-th>Данные</b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>

      <b-tbody>
        <b-tr v-for="(item, index) in transactions.items" :key="index">
          <b-th class="text-black font-weight-normal text-nowrap">
            {{ item.date }}<br />
            <span class="text-muted">{{ item.time }}</span>
          </b-th>
          <b-th class="text-black font-weight-normal">
            <span v-if="item.out">-</span>
            {{ item.amount }} ₽
            <div class="d-flex align-items-center" v-if="!item.out">
              <div class="cicle-badge-view cicle-outline-success"></div>
              <span>Начислено</span>
            </div>
            <div
              class="d-flex align-items-center"
              v-else-if="item.out && item.action == 1"
            >
              <div class="cicle-badge-view cicle-warning"></div>
              <span>Отправлено</span>
            </div>
            <div
              class="d-flex align-items-center"
              v-else-if="item.out && item.action == 2"
            >
              <div class="cicle-badge-view cicle-primary"></div>
              <span>Выведено</span>
            </div>
          </b-th>
          <b-th class="text-black font-weight-normal">
            <div v-if="!item.out">
              <p class="mb-0">
                Начисление за заявку по продукту {{ item.offer.title }}
              </p>
              <small class="text-muted">Заявка #{{ item.order.ouid }}</small>
            </div>
          </b-th>
          <b-th class="text-black font-weight-normal">
            <b-button
              v-if="item.out"
              variant="secondary"
              style="word-break: break-all; width: 140px"
              >Скачать чек</b-button
            >
            <b-button
              v-else
              variant="secondary"
              style="word-break: break-all; width: 140px"
              >Вывести</b-button
            >
          </b-th>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-card-body class="d-flex justify-content-between pt-0 pb-0">
      <span class="font-weight-bolder">Всего: {{ transactions.total_count }}</span>

      <b-pagination-nav
        :value="transactions.current_page"
        :link-gen="linkGen"
        :number-of-pages="transactions.last_page"
        use-router
      ></b-pagination-nav>
    </b-card-body>
  </b-card>
</template>

<script>
import FiltersView from "../widgets/filters";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      is_loading: false,
      filter: {select: 'today'},
      transactions: {
        items: [
          // {
          //   id: 1,
          //   date: '31 октября',
          //   time: '13:00',
          //   amount: 1000,
          //   out: 0,
          //   action: null,
          //   data: "Начисление за заявку по продукту OZON | Озон кладовщик. Валидная заявка ",
          // },
          // {
          //   id: 1,
          //   date: '32 октября',
          //   time: '11:00',
          //   amount: 500,
          //   out: 1,
          //   action: 1,
          //   data: "Начисление за заявку по продукту OZON | Озон кладовщик. Валидная заявка ",
          // },
          // {
          //   id: 1,
          //   date: '32 октября',
          //   time: '05:00',
          //   amount: 1500,
          //   out: 1,
          //   action: 2,
          //   data: "Начисление за заявку по продукту OZON | Озон кладовщик. Валидная заявка ",
          // }
        ],
        last_page: null,
        current_page: 1,
        total_count: null,
      },
    };
  },
  components: {
    FiltersView,
  },
  methods: {
    linkGen(pageNum) {
      return {
        name: this.$route.name,
        query: {
          page: pageNum,
        },
      };
    },
    get() {
      this.is_loading = true;

      this.$request.get("transactions.list", {
        filter: this.filter,
        page: this.$route.query
      }).then((rsp) => {
        this.transactions.items = rsp.items;
        this.transactions.total_count = rsp.total_count;
        this.transactions.current_page = rsp.current_page;
        this.transactions.last_page = rsp.last_page;
        this.is_loading = false;
      });
    },
    changeFilter(filter) {
      this.filter = filter;
      this.get();
    },
    exportExcel() {
      if (this.transactions.items.length > 0)  {
        this.$request.export("export/transactions", this.filter).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let fileName = 'история_операций';
          fileName += '.xlsx';
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            text: "Нет данных для для отчета.",
            icon: "CheckCircleIcon",
            variant: "warning",
          },
        });
      }
    },
  },
  mounted() {
    this.get();
  },
};
</script>

<style></style>
