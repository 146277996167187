<template>
  <div class="new-card-content">

    <b-card-body style="padding: 0">
      <b-card-title>Добавьте данные карты</b-card-title>
      
      <validation-observer ref="new_card" >
        <b-form class="client-form">
          <b-row>
            <b-col cols="12" md="6" lg="6">
              <b-form-group
                label="Номер карты"
                label-for="number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Номер карты"
                  rules="required"
                >
                  <b-form-input
                    placeholder="Номер карты"
                    v-model="form.number"
                    v-mask="'#### #### #### #######'"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

           <b-col cols="12" md="6" lg="6">
              <b-form-group
                label="Имя"
                label-for="holder_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Имя"
                  rules="required"
                >
                  <b-form-input
                    placeholder="Имя владельца"
                    v-model="form.holder_name"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6">
              <b-form-group
                label="Срок годности"
                label-for="expiration"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Срок годности"
                  :rules="{ required: true, regex: /(0[1-9]|1[0-2])\/[2-9]{2}/ }"
                >
                <!-- regex: /^[0-9]+\/^[0-9]+$/ -->
                  <!-- (0[1-9]|1[0-2])\/[0-9]{2} -->
                  <!-- rules="required|length:4" -->
                  <b-form-input
                    v-model="form.expiration"
                    name="Expiration"
                    placeholder="Срок годности"
                    v-mask="'##/##'"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

             <b-col cols="12" md="6" lg="6">
              <b-form-group
                label="CVV/CVC"
                label-for="cvv"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CVV/CVC"
                  rules="required|length:3"
                >
                  <b-form-input
                    v-model="form.cvv"
                    name="CVV/CVC"
                    placeholder="CVV/CVC"
                    v-mask="'###'"
                    type="password"
                  /> 

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" class="d-flex">
              <b-button
                type="submit"
                @click.prevent="submit"
                block
                variant="primary"
                :disabled="is_loading"
              >
                Сохранить реквизиты
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  data() {
    return {
      is_loading: false,
      form: {
        number: '',
        holder_name: '',
        expiration: '',
        cvv: '',
      },
    };
  },
  methods: {
    submit() {
      this.$refs.new_card.validate().then(success => {
        debugger
        if (!success) return;
        // this.is_loading = true;
        // this.$request.post("finance.add", this.form ).then( rsp => {
        //   this.is_loading = false;
        // });
      });
    },
  },

  computed: {},

  mounted() {},

  watch: {
   "form.holder_name" : {
    handler(bef) {
        this.form.holder_name = this.form.holder_name.toUpperCase();
    }
   }
  },

  components: {
    ValidationProvider,
    ValidationObserver,

  },
};
</script>

<style lang="scss" scoped>

</style>
