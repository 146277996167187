<template>
  <div>
    <div>
      <p class="main-header-section">
        Финансы
      </p>
    </div>
    <div class="d-flex finance-wrapper">
      <div style="width: 340px">
        <div style="position: sticky; top: 16px">
          <basic-balance-view />
          <inside-balance-view />
          <bank-cards-view />
        </div>
      </div>
      <div style="width: calc(100% - 340px); padding-left: 20px">
        <transactions-desktop-view
          ref="transactions"
        />
      </div>
    </div>
    
  </div>
</template>

<script>
import User from "@/modules/user/";

import BasicBalanceView from "./widgets/basic.desktop"
import InsideBalanceView from "./widgets/inside.desktop"

import BankCardsView from "./widgets/cards.desktop"

import TransactionsDesktopView from "./transactions/transactions.desktop"

export default {
  data() {
    return {
      wallet: null,

      modalsVisibility: {
        input: false,
        output: false,
        transfer: false,
      },
    };
  },
  methods: {
    isActive(value) {
      return this.selectedWalletIndex === value;
    },

    closeModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });
    },

    showModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });

      if (this.modalsVisibility.hasOwnProperty(action)) {
        this.modalsVisibility[action] = true;
      }
    },
  },
  components: {
    BasicBalanceView,
    InsideBalanceView,
    BankCardsView,
    TransactionsDesktopView,
  },

  computed: {},

  watch: {},
  mounted() {},
};
</script>
